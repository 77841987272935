<template>
  <div class="workorderreleasetable-container">
    <el-table
      :data="tableData"
      style="width: 100%"
      class="workorderreleasetable"
      :stripe="true"
    >
      <!-- 序号 -->
      <el-table-column
        type="index"
        align="center"
        label="序号"
        width="80"
        fixed
      >
      </el-table-column>
      <!-- 业务类型 -->
      <el-table-column prop="business_type" label="业务类型" align="center">
      </el-table-column>
      <!-- 公司名称 -->
      <el-table-column prop="corporate_name" label="公司名称" align="center">
      </el-table-column>
      <!-- 发布人 -->
      <el-table-column prop="publisher" label="发布人" align="center">
      </el-table-column>
      <!-- 发布时间 -->
      <el-table-column prop="release_time" label="发布时间" align="center">
      </el-table-column>
      <!-- 到期时间 -->
      <el-table-column prop="expiration_time" label="到期时间" align="center">
      </el-table-column>
      <!-- 接收人 -->
      <el-table-column prop="receiver" label="接收人" align="center">
      </el-table-column>
      <!-- 任务状态 -->
      <el-table-column prop="state" label="任务状态" align="center">
      </el-table-column>
      <!-- 任务详细 -->
      <el-table-column prop="task_details" label="任务详细" align="center">
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="180" align="center">
        <template slot-scope="scope">
          <el-button
            @click="handleClick(scope.row)"
            type="button"
            class="seebtn"
            >详情</el-button
          >
          <el-button type="button" class="withdrawbtn">撤回</el-button>
        </template>
      </el-table-column>
    </el-table>
    <pagination-com></pagination-com>
    <!-- <taskdetails-com
      :taskdetailsVisible="taskdetailsVisible"
      @taskdetailsVisibleClose="taskdetailsVisibleClose"
      :rowdata='rowdata'
    ></taskdetails-com> -->
    <myorderdetails-com
      :myworkdetailsdetailsVisible="myworkdetailsdetailsVisible"
      @myworkdetailsdetailsVisibleClose="myworkdetailsdetailsVisibleClose"
      :rowdata="rowdata"
    ></myorderdetails-com>
  </div>
</template>


<script>

// import taskdetailsCom from './taskdetailsCom.vue'
import myorderdetailsCom from './workorderreleasedetailsCom.vue'
import paginationCom from '../paginationCom.vue'



export default {
  components: {
    // taskdetailsCom
    myorderdetailsCom, paginationCom
  },
  data() {
    return {
      myworkdetailsdetailsVisible: false,
      rowdata: {},
      /**
       * 业务类型:business_type
       * 公司名称:corporate_name
       * 发布人:publisher
       * 发布时间:release_time
       * 到期时间:expiration_time
       * 接收人:receiver
       * 任务状态:state
       * 任务详细:task_details
       */
      //   最多13条数据可以展示在页面上  所以一页就是13条
      tableData: [{
        releaser_area: '百色区',
        receiver_area: '南宁青秀',
        corporate_name: '广西天灿人力资源有限公司',
        business_type: '大厅申报',
        publisher: '天小餐',
        receiver: '天璨璨',
        release_time: '2021-07-01 15:34:05',
        expiration_time: '2021-07-01 15:34:05',
        contacts: '灿灿天',
        contacts_phone: '16099996666',
        address: '广西西乡塘区科德西路7号嘉士通金广场8335号',
        customerservice_area: '青秀区',
        customerservice: '天灿灿 1906668888',
        handle_area: '青秀区',
        task_status: '已完成',
        task_details: '信息详细展示',
        task_ps: '备注展示'
      },
      {
        releaser_area: '崇左区',
        receiver_area: '南宁江南',
        corporate_name: '广西天灿人力资源有限公司',
        business_type: '大厅申报',
        publisher: '天小餐',
        receiver: '天璨璨',
        release_time: '2021-07-01 15:34:05',
        expiration_time: '2021-07-01 15:34:05',
        contacts: '灿灿天',
        contacts_phone: '16099996666',
        address: '广西西乡塘区科德西路7号嘉士通金广场8335号',
        customerservice_area: '青秀区',
        customerservice: '天灿灿 1906668888',
        handle_area: '青秀区',
        task_status: '进行中',
        task_details: '信息详细展示',
        task_ps: '备注展示'
      },
      ],
    }
  },
  props: {

  },
  methods: {
    handleClick(row) {
      console.log(row);
      this.rowdata = row
      this.myworkdetailsdetailsVisible = true
      // console.log(this.rowdata);
    },
    myworkdetailsdetailsVisibleClose() {
      this.myworkdetailsdetailsVisible = false
    }
  }
}
</script>



<style lang="scss">
.workorderreleasetable-container {
  background-color: #ffffff;
  padding-bottom: 20px;
  .workorderreleasetable {
    height: 950px;
    background-color: #ffffff;
    thead {
      th {
        color: #ffffff;
        background-color: $index-buttonPor-color;
      }
    }
    .seebtn {
      background-color: $index-buttonPor-color;
      color: #ffffff;
    }
    .withdrawbtn {
      background-color: $index-buttonerr-color;
      color: #ffffff;
    }
  }
}
</style>