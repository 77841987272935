<template>
  <div>
    <el-dialog
      width="1000px"
      title="工单详情"
      :visible.sync="myworkdetailsdetailsVisible"
      append-to-body
      @close="myworkdetailsdetailsVisibleClose"
      :before-close="myworkdetailsdetailsVisibleClose"
      custom-class="myorderdetails-dialog"
      top="5%"
    >
      <div class="myorderdetails-container">
        <div class="myorderdetails-gridContainer">
          <!-- 发布人片区 -->
          <div class="myorderdetails-gridItem">
            <span class="myorderdetails-gridItem-title">发布人片区:</span>
            <span>{{ rowdata.releaser_area }}</span>
          </div>
          <!-- 发布人 -->
          <div class="myorderdetails-gridItem">
            <span class="myorderdetails-gridItem-title">发布人:</span>
            <span>{{ rowdata.publisher }}</span>
          </div>
          <!-- 发布日期 -->
          <div class="myorderdetails-gridItem">
            <span class="myorderdetails-gridItem-title">发布日期:</span>
            <span>{{ rowdata.expiration_time }}</span>
          </div>
          <!-- 接收人片区 -->
          <div class="myorderdetails-gridItem">
            <span class="myorderdetails-gridItem-title">接收人片区:</span>
            <span>{{ rowdata.receiver_area }}</span>
          </div>
          <!-- 接收人 -->
          <div class="myorderdetails-gridItem">
            <span class="myorderdetails-gridItem-title">接收人:</span>
            <span>{{ rowdata.receiver }}</span>
          </div>
          <!-- 到期日期 -->
          <div class="myorderdetails-gridItem">
            <span class="myorderdetails-gridItem-title">到期日期:</span>
            <span>{{ rowdata.release_time }}</span>
          </div>
          <!-- 公司名称 -->
          <div class="myorderdetails-gridItem">
            <span class="myorderdetails-gridItem-title">公司名称:</span>
            <span>{{ rowdata.corporate_name }}</span>
          </div>
          <!-- 联系人 -->
          <div class="myorderdetails-gridItem">
            <span class="myorderdetails-gridItem-title">联系人:</span>
            <span>{{ rowdata.contacts }}</span>
          </div>
          <!-- 联系电话 -->
          <div class="myorderdetails-gridItem">
            <span class="myorderdetails-gridItem-title">联系电话:</span>
            <span>{{ rowdata.contacts_phone }}</span>
          </div>
          <!-- 地址 -->
          <div class="myorderdetails-gridItem">
            <span class="myorderdetails-gridItem-title address">地址:</span>
            <span>{{ rowdata.address }}</span>
          </div>
          <!-- 客服片区 -->
          <div class="myorderdetails-gridItem">
            <span class="myorderdetails-gridItem-title">客服片区:</span>
            <span>{{ rowdata.customerservice_area }}</span>
          </div>
          <!-- 客服 -->
          <div class="myorderdetails-gridItem">
            <span class="myorderdetails-gridItem-title">客服:</span>
            <span>{{ rowdata.customerservice }}</span>
          </div>
          <!-- 办理片区 -->
          <div class="myorderdetails-gridItem myorderdetails-biggridItem">
            <span class="myorderdetails-gridItem-title">办理片区:</span>
            <span>{{ rowdata.handle_area }}</span>
          </div>
        </div>
        <!-- 服务项目 -->
        <div class="myorderdetails-serviceContainer">
          <p class="myorderdetails-service-title">服务项目:</p>
          <el-checkbox-group v-model="serviceList">
            <el-checkbox label="注册公司" checked></el-checkbox>
            <el-checkbox label="申请发票" checked></el-checkbox>
            <el-checkbox label="其他服务项目(具体写)" checked></el-checkbox>
          </el-checkbox-group>
        </div>
        <!-- 客户提供资料 -->
        <div class="myorderdetails-custermerInformationContainer">
          <p class="myorderdetails-custermerInformation-title">客户提供资料:</p>
          <div class="myorderdetails-custermerInformationItemContainer">
            <i class="iconfont icon-yingyezhizhao"></i>
            <span class="myorderdetails-custermerInformationItem"
              >营业驾照正本</span
            >

            <span class="myorderdetails-custermerInformationItem"
              ><i class="iconfont icon-kaihuxukezheng"></i>开户许可证</span
            >

            <span class="myorderdetails-custermerInformationItem"
              ><i class="iconfont icon-fangchanzhengming"></i>房产证复印件</span
            >

            <span class="myorderdetails-custermerInformationItem"
              ><i class="iconfont icon-credentials_icon"></i>身份证</span
            >

            <span class="myorderdetails-custermerInformationItem"
              ><i class="iconfont icon-qita"></i>其他资料(具体写)</span
            >
          </div>
        </div>
        <!-- 任务备注 -->
        <div class="myorderdetails-taskNoteContainer">
          <p class="myorderdetails-taskNote-title">任务备注:</p>
          <div class="myorderdetails-taskNote">
            信息信息信息信息信息信息信息信息信息信息信息信息信息信息信息信息
          </div>
        </div>
        <div class="myorderdetails-progressContainer">
          <div class="myorderdetails-progresstitle">工单进度:</div>
          <el-steps
            :active="3"
            class="myorderdetails-progress-steps"
            space="20%"
            align-center
          >
            <el-step
              title="天小小(主管)"
              icon="el-icon-upload"
              description="已分配"
            >
              <template slot="description">
                <div>
                  <p>已分配</p>
                  <p
                    style="
                      font-family: PingFangSC-Light, sans-serif;
                      font-size: 12px;
                      color: #999999;
                    "
                  >
                    2021.08.01 15:00:05
                  </p>
                </div>
              </template>
              <template slot="icon">
                <i
                  class="iconfont icon-queren"
                  style="
                    position: absolute;
                    left: 29px;
                    top: 24px;
                    color: #0ad162;
                    font-size: 12px;
                  "
                ></i>
                <img
                  src="../../assets/taskavaimg.png"
                  style="height: 50px; width: 50px"
                  class="myorderdetails-progress-steps-img"
                />
              </template>
            </el-step>
            <el-step
              title="天灿灿(外勤)"
              icon="el-icon-upload"
              description="已完成"
            >
              <template slot="description">
                <div>
                  <p>已分配</p>
                  <p
                    style="
                      font-family: PingFangSC-Light, sans-serif;
                      font-size: 12px;
                      color: #999999;
                    "
                  >
                    2021.08.01 15:00:05
                  </p>
                </div>
              </template>
              <template slot="icon">
                <i
                  class="iconfont icon-queren"
                  style="
                    position: absolute;
                    left: 29px;
                    top: 24px;
                    color: #0ad162;
                    font-size: 12px;
                  "
                ></i>
                <img
                  src="../../assets/taskavaimg.png"
                  style="height: 50px; width: 50px"
                  class="myorderdetails-progress-steps-img"
                />
              </template>
            </el-step>
            <el-step
              title="天小小(主管)"
              icon="el-icon-upload"
              description="已确定"
            >
              <template slot="description">
                <div>
                  <p>已分配</p>
                  <p
                    style="
                      font-family: PingFangSC-Light, sans-serif;
                      font-size: 12px;
                      color: #999999;
                    "
                  >
                    2021.08.01 15:00:05
                  </p>
                </div>
              </template>
              <template slot="icon">
                <i
                  class="iconfont icon-queren"
                  style="
                    position: absolute;
                    left: 29px;
                    top: 24px;
                    color: #0ad162;
                    font-size: 12px;
                  "
                ></i>
                <img
                  src="../../assets/taskavaimg.png"
                  style="height: 50px; width: 50px"
                  class="myorderdetails-progress-steps-img"
                />
              </template>
            </el-step>
            <el-step
              title="天大大(客服)"
              icon="el-icon-upload"
              description="审核中"
            >
              <template slot="icon">
                <i
                  class="iconfont icon-queren"
                  style="
                    position: absolute;
                    left: 29px;
                    top: 24px;
                    color: #0ad162;
                    font-size: 12px;
                  "
                ></i>
                <img
                  src="../../assets/taskavaimg.png"
                  style="height: 50px; width: 50px"
                  class="myorderdetails-progress-steps-img"
                />
              </template>
            </el-step>
            <el-step
              title="天天灿(片管)"
              icon="el-icon-upload"
              description="待确认"
            >
              <template slot="icon">
                <i
                  class="iconfont icon-queren"
                  style="
                    position: absolute;
                    left: 29px;
                    top: 24px;
                    color: #999999;
                    font-size: 12px;
                  "
                ></i>
                <img
                  src="../../assets/taskavaimg.png"
                  style="height: 50px; width: 50px"
                  class="myorderdetails-progress-steps-img"
                />
              </template>
            </el-step>
            <el-step
              title="天小灿(会计)"
              icon="el-icon-upload"
              description="待确认"
            >
              <template slot="icon">
                <i
                  class="iconfont icon-queren"
                  style="
                    position: absolute;
                    left: 29px;
                    top: 24px;
                    color: #999999;
                    font-size: 12px;
                  "
                ></i>
                <img
                  src="../../assets/taskavaimg.png"
                  style="height: 50px; width: 50px"
                  class="myorderdetails-progress-steps-img"
                />
              </template>
            </el-step>
          </el-steps>
        </div>
        <div class="myorderdetails-customerdetailsContainer">
          <el-tabs v-model="activeName" type="card" class="myworktabs">
            <el-tab-pane label="代收支出" name="first">
              <div class="money-container">
                <div class="money-details">
                  <div class="detailsItem">
                    <span>业务金额(元):</span><span>2400元</span>
                  </div>
                  <div class="detailsItem">
                    <span>收款情况:</span><span></span>
                  </div>
                  <div class="detailsItem">
                    <span>收款金额:</span><span>800元</span>
                  </div>
                  <div class="detailsItem">
                    <span>待支金额:</span><span>800元</span>
                  </div>
                  <div class="detailsItem">
                    <span>有效业绩:</span><span>800元</span>
                  </div>
                  <div class="detailsItem">
                    <span>提成(元):</span><span>800元</span>
                  </div>
                  <div class="detailsItem">
                    <span>提成计提:</span><span>800元</span>
                  </div>
                  <div class="detailsItem">
                    <span>收支平衡:</span><span>800元</span>
                  </div>
                </div>
                <el-table
                  :data="payData"
                  style="width: 100%"
                  :header-cell-style="{ background: '#8674eb', color: '#fff' }"
                  :stripe="true"
                  :row-class-name="tableRowClassName"
                >
                  <el-table-column type="index" label="序号" align="center">
                  </el-table-column>
                  <!-- 收款金额 -->
                  <el-table-column
                    prop="income"
                    label="收款金额(元)"
                    align="center"
                  >
                  </el-table-column>
                  <!-- 收款类型 -->
                  <el-table-column
                    prop="collectiontype"
                    label="收款类型"
                    align="center"
                  >
                  </el-table-column>
                  <!-- 收款收款时间 -->
                  <el-table-column
                    prop="collectiontime"
                    label="收款时间"
                    align="center"
                  >
                  </el-table-column>
                  <!-- 收款截图 -->
                  <el-table-column label="收款截图" align="center">
                    <template slot-scope="scope">
                      <div class="flexBox">
                        <div
                          class="screenshotItem"
                          v-for="(item, index) in scope.row
                            .collectionscreenshot"
                          :key="index"
                        >
                          <img
                            src="../../assets/shouju.png"
                            alt=""
                            height="40px"
                          />
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                  <!-- 备注 -->
                  <el-table-column prop="notes" label="备注" align="center">
                  </el-table-column>

                  <el-table-column
                    prop="operation"
                    fixed="right"
                    label="操作"
                    align="center"
                  >
                    <template scope="scope">
                      <span v-if="scope.row.operation === '已完成'"
                        >已完成</span
                      >
                      <span
                        v-else-if="scope.row.operation === '退款'"
                        style="color: red"
                        >退款</span
                      >
                      <span v-else style="color: red">已驳回</span>
                    </template>
                  </el-table-column>
                </el-table>
                <div class="adddetails">
                  <el-button class="addbtn">添加收支</el-button>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane
              label="交接记录"
              name="handoverRecord"
              class="myorderdetails-handoverRecordContainer"
            >
              <el-table
                style="width: 100%"
                :data="tableData"
                class="myorderdetails-handoverRecord"
                :header-cell-style="{
                  background: '#8674eb',
                  color: '#fff',
                }"
                :stripe="true"
              >
                <el-table-column
                  type="index"
                  label="序号"
                  align="center"
                  class="handoverRecord-thead"
                ></el-table-column>
                <el-table-column label="交接内容" align="center" prop="name">
                </el-table-column>
                <el-table-column label="图片" align="center">
                  <template slot-scope="scope">
                    <div class="flexBox">
                      <div
                        class="screenshotItem"
                        v-for="(item, index) in scope.row.payment_screenshot"
                        :key="index"
                      >
                        <img
                          src="../../assets/taskavaimg.png"
                          alt=""
                          height="60px"
                        />
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  label="交接时间"
                  align="center"
                  prop="date"
                ></el-table-column>
                <el-table-column
                  label="上传人"
                  align="center"
                  prop="name"
                ></el-table-column>
              </el-table>
              <div class="adddetails">
                <el-button class="addbtn">添加记录</el-button>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="myorderdetails-psContainer">
          <div class="myorderdetails-statetitle">备注内容:</div>
          <div class="myorderdetails-psitem">
            <div class="psleft">
              <img src="../../assets/taskavaimg.png" alt="" class="psava" />
              <div class="pscontent">
                <div class="contenttitle">
                  <span>天灿灿</span
                  ><span style="marginleft: 20px">2021-07-30 15:23:58</span>
                </div>
                <div class="content">客户已通过转账2400元完成结款</div>
                <div class="picture">
                  <img src="../../assets/shouju.png" alt="" />
                  <img src="../../assets/shouju.png" alt="" />
                </div>
              </div>
            </div>
            <div class="addimgbtn-container">
              <el-button class="addimg">添加图片</el-button>
            </div>
          </div>
          <div class="addpsbtn-container">
            <el-button class="addpsbtn">添加备注</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>


<script>
export default {
  data() {
    return {
      activeName: 'first',
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        province: '上海',
        city: '普陀区',
        address: '上海市普陀区金沙江路 1518 弄',
        zip: 200333,
        payment_screenshot: ['../../assets/taskavaimg.png',],
      }, {
        date: '2016-05-04',
        name: '王小虎',
        province: '上海',
        city: '普陀区',
        address: '上海市普陀区金沙江路 1517 弄',
        zip: 200333,
        payment_screenshot: ['../../assets/taskavaimg.png', '../../assets/taskavaimg.png'],
      }, {
        date: '2016-05-01',
        name: '王小虎',
        province: '上海',
        city: '普陀区',
        address: '上海市普陀区金沙江路 1519 弄',
        zip: 200333,
        payment_screenshot: [],
      }, {
        date: '2016-05-03',
        name: '王小虎',
        province: '上海',
        city: '普陀区',
        address: '上海市普陀区金沙江路 1516 弄',
        zip: 200333,
        payment_screenshot: ['../../assets/taskavaimg.png', '../../assets/taskavaimg.png', '../../assets/taskavaimg.png'],
      }],
      serviceList: [],
      payData: [{
        income: '20000(实收) 20000(代收)',
        collectiontype: '支付宝',
        collectiontime: '2021-08-01 16:05:05',
        collectionscreenshot: ['../../assets/shouju.png'],
        notes: '代收+实收截图',
        operation: '已驳回'
      },
      {
        income: '200(代收)',
        collectiontype: '支付宝',
        collectiontime: '2021-08-05 16:05:05',
        collectionscreenshot: ['../../assets/shouju.png'],
        notes: '无',
        operation: '已完成'
      },
      {
        income: '20000(实收)',
        collectiontype: '支付宝',
        collectiontime: '2021-08-08 16:05:05',
        collectionscreenshot: ['../../assets/shouju.png'],
        notes: '无',
        operation: '已完成'
      },
      {
        income: '20000(退款)',
        collectiontype: '支付宝',
        collectiontime: '2021-08-01 16:05:05',
        collectionscreenshot: ['../../assets/shouju.png'],
        notes: '无',
        operation: '退款'
      },
      {
        income: '20000(支出)',
        collectiontype: '微信',
        collectiontime: '2021-08-10 16:05:05',
        collectionscreenshot: ['../../assets/shouju.png'],
        notes: '办章费用',
        operation: '已完成'
      }

      ]
    }
  },
  props: {
    myworkdetailsdetailsVisible: {
      type: Boolean,
      default: false
    },
    rowdata: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  mounted() {
    console.log();
  },
  methods: {
    myworkdetailsdetailsVisibleClose() {
      console.log('内置dialog被关闭')
      this.$emit('myworkdetailsdetailsVisibleClose')
    },
    tableRowClassName({ row }) {
      // console.log(row);
      // console.log(rowIndex);
      if (row.operation === "退款") {
        return 'warning-row';
      }
      //  else if (rowIndex === 3) {
      //   return 'success-row';
      // }
      return '';
    }
  },
}
</script>

<style lang="scss">
.myorderdetails-dialog {
  .el-dialog__header {
    background-color: $index-buttonPor-color;
    font-size: 20px;
    font-weight: bold;
    .el-dialog__title {
      color: #ffffff;
      font-size: 20px;
      font-weight: bold;
    }
    .el-dialog__headerbtn {
      font-size: 20px;
      color: #ffffff;
      .el-icon-close {
        color: #ffffff;
      }
    }
  }
  .el-dialog__body {
    height: 940px;
    overflow: auto;
    padding: 25px;
    word-break: unset;
    .myorderdetails-customerdetailsContainer {
      min-height: 300px;
      // border-bottom: 2px solid #f2f2f2;
      padding: 20px 0;
      box-sizing: border-box;
      .el-tabs--card > .el-tabs__header,
      .el-tabs--card > .el-tabs__header .el-tabs__nav {
        border: none;
      }
      .el-tabs__item {
        color: #8674eb !important;
        border: none;
        border-radius: 8px;
        background-color: white !important;
      }
      .is-active {
        // background-color: $index-blue-color !important;
        background-color: #8674eb !important;
        color: #ffffff !important;
      }

      .money-container {
        .money-details {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          margin-bottom: 20px;
          grid-row-gap: 20px;
          grid-column-gap: 20px;
        }
        .el-table {
          th {
            background-color: #f2f2f2;
          }
          th,
          td {
            border: none;
          }
        }
        .el-table .cell {
          word-break: normal;
        }
        .el-table::before {
          height: 0;
        }
        .adddetails {
          margin-top: 20px;
          text-align: center;
          .addbtn {
            background-color: #8674eb !important;
            color: #ffffff;
          }
        }
      }
    }
    .myorderdetails-gridContainer {
      display: grid;
      grid-template-columns: 1.3fr 0.5fr 0.8fr;
      grid-row-gap: 20px;
      grid-column-gap: 130px;
      border-bottom: 2px solid #f2f2f2;
      padding-bottom: 30px;
      .myorderdetailsflex-top {
        align-items: flex-start !important;
      }
      .myorderdetails-biggridItem {
        grid-column-start: span 2;
      }
      .myorderdetails-gridItem {
        display: flex;
        // align-items: center;
        .address {
          width: 40px;
        }
        .myorderdetails-gridItem-title {
          // width: 100px;
        }
        .el-input,
        .el-autocomplete {
          flex: 1;
        }
      }
    }
    /*服务项目*/
    .myorderdetails-serviceContainer {
      padding: 30px 0;
      // border-bottom: 2px solid #f2f2f2;
      .el-checkbox-group {
        padding: 30px 0px 0px 30px;
        .el-checkbox {
          margin-right: 80px;
        }
        .el-checkbox .el-checkbox__input.is-checked + .el-checkbox__label {
          color: #606266;
        }
        .el-checkbox .el-checkbox__input.is-checked .el-checkbox__inner,
        .el-checkbox .el-checkbox__input.is-indeterminate .el-checkbox__inner {
          border-color: #8674eb;
          background-color: #8674eb;
        }
      }
    }
    // 客户提供资料
    .myorderdetails-custermerInformationContainer {
      padding-bottom: 30px;
      border-bottom: 2px solid #f2f2f2;
      .myorderdetails-custermerInformationItemContainer {
        padding: 30px 0px 0px 30px;
        .iconfont {
          color: #8674eb;
          margin-right: 10px;
        }
        .myorderdetails-custermerInformationItem {
          // padding-left: 10px;
          margin-right: 50px;
        }
      }
    }
    .myorderdetails-taskNoteContainer {
      padding: 30px 0;
      border-bottom: 2px solid #f2f2f2;
      .myorderdetails-taskNote {
        padding: 30px 0px 0px 30px;
        // color:#000;
      }
    }
    // 工单进展
    .myorderdetails-progressContainer {
      padding: 30px 0;
      border-bottom: 2px solid #f2f2f2;
      .myorderdetails-progresstitle {
        margin-bottom: 20px;
      }
      .myorderdetails-progress-steps {
        .el-step__title,
        .el-step__description {
          font-size: 12px;
          color: #666666;
        }
        .el-step__title.is-process {
          font-weight: normal;
        }
        .el-step__head.is-finish .el-step__line {
          background: #0ad162;
        }
        .el-step__line {
          height: 1px;
        }
        .el-step__line-inner {
          border-style: none;
        }
        .el-step.is-center .el-step__line {
          left: 80%;
          right: -20%;
        }
        .myorderdetails-progress-steps-img {
          border-radius: 50%;
        }
      }
    }
    //交接记录
    .myorderdetails-handoverRecordContainer {
      .myorderdetails-handoverRecord {
        .el-table--striped .el-table__body tr.el-table__row--striped td {
          background: #f9f9f9;
        }
        th {
          background-color: #f2f2f2;
        }
        th,
        td {
          border: none;
        }
        .flexBox {
          display: flex;
          .screenshotItem {
            // margin-left: 10px;
            flex: 1;
            img {
              height: 30px;
            }
          }
        }
      }
      .myorderdetails-handoverRecord::before {
        height: 0;
      }
      .adddetails {
        margin-top: 20px;
        text-align: center;
        .addbtn {
          background-color: #8674eb !important;
          color: #ffffff;
        }
      }
    }

    .myorderdetails-psContainer {
      padding: 30px 0;
      .myorderdetails-psitem:hover {
        background-color: #f9f9f9;
      }
      .myorderdetails-psitem {
        padding: 20px;
        display: flex;
        justify-content: space-between;
        .psleft {
          display: flex;
          .psava {
            width: 40px;
            height: 40px;
            margin-right: 20px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .contenttitle,
          .content {
            margin-bottom: 2px;
          }
          .picture {
            // width: 80px;
            height: 30px;
            display: flex;
            img {
              width: 100%;
              height: 100%;
              margin-right: 5px;
            }
          }
        }
        .addimg {
          background-color: $index-buttonPor-color;
          color: #ffffff;
        }
      }
      .addpsbtn-container {
        text-align: center;
        padding-top: 10px;
        .addpsbtn {
          background-color: $index-buttonPor-color;
          color: #ffffff;
        }
      }
    }
  }
  .el-dialog__footer {
    .dialog-footer {
      .el-button {
        border: 1px solid $index-buttonPor-color;
        color: $index-buttonPor-color;
      }
      .resBtn {
        background-color: $index-buttonPor-color;
        color: #ffffff;
      }
    }
  }
}
</style>
