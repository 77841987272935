<template>
  <div class="workorder-release-container">
    <div class="workorderrelease-BtnContainer">
      <el-button
        type="primary"
        icon="el-icon-circle-plus-outline"
        class="addBtn"
        @click="addworkorderVisible = true"
        >新增</el-button
      >
      <el-input
        placeholder="搜索"
        suffix-icon="el-icon-search"
        v-model="input1"
        class="searchinput"
      >
      </el-input>
    </div>
    <div class="workorderreleasetable-container">
      <workorderreleasetable-com></workorderreleasetable-com>
      <!-- <workorderreleasetable-com></workorderreleasetable-com> -->
    </div>
    <!-- <pagination-com></pagination-com> -->
    <!-- 
    <addworkorder-com
      :addworkorderVisible="addworkorderVisible"
      @addworkorderVisibleClose="addworkorderVisibleClose"
    ></addworkorder-com> -->
    <Addworkorder-com
      :addworkorderVisible="addworkorderVisible"
      @addworkorderVisibleClose="addworkorderVisibleClose"
    ></Addworkorder-com>
  </div>
</template>


<script>
// import workorderreleasetableCom from '../components/workorder-release/workorderreleasetableCom'

// import addworkorderCom from '../components/workorder-release/addworkorderCom.vue'

import workorderreleasetableCom from '../../components/workordermanagement/workorderreleasetableCom.vue'
import AddworkorderCom from '../../components/workordermanagement/addworkorderCom.vue'
export default {
  data() {
    return {
      input1: '',
      addworkorderVisible: false,
      workorderdetailsVisible: true
    }
  },
  components: {
    // workorderreleasetableCom, paginationCom, addworkorderCom, 
    workorderreleasetableCom,
    AddworkorderCom
  },
  methods: {
    addworkorderVisibleClose() {
      this.addworkorderVisible = false
    },
  
  }
}
</script>


<style lang="scss">
.workorder-release-container {
  box-sizing: border-box;
  padding: 30px 50px;
  background-color: #f2f2f2;
  height: 100%;
  .workorderrelease-BtnContainer {
    text-align: left;
    margin-bottom: 50px;
    .addBtn {
      background-color: $index-buttonPor-color;
      font-size: 16px;
      border: none;
    }
    .searchinput {
      width: 300px;
      margin-left: 20px;
    }
  }
  .workorderreleasetable-container {
    // height: 900px;
    overflow: hidden;
  }
}
</style>  