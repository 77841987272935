<template>
  <!-- 新增工单 -->
  <div>
    <el-dialog
      width="1000px"
      title="新增工单"
      :visible.sync="addworkorderVisible"
      append-to-body
      @close="addworkorderVisibleClose"
      :before-close="addworkorderVisibleClose"
      top="5%"
      custom-class="addworkorder-dialog"
    >
      <div class="addworkorder-container">
        <div class="addworkorder-gridContainer">
          <!-- 客服片区 -->
          <div class="addworkorder-gridItem">
            <span class="addworkorder-gridItem-title">客服片区:</span>
            <el-select v-model="value" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <!-- 客服名称 -->
          <div class="addworkorder-gridItem">
            <span class="addworkorder-gridItem-title">客服名称:</span>
            <el-input v-model="input" placeholder="请输入内容"></el-input>
          </div>
          <!-- 客服电话 -->
          <div class="addworkorder-gridItem">
            <span class="addworkorder-gridItem-title">客服电话:</span>
            <el-input v-model="input" placeholder="请输入内容"></el-input>
          </div>
          <!-- 发布人片区 -->
          <div class="addworkorder-gridItem">
            <span class="addworkorder-gridItem-title">发布人片区:</span>
            <el-select v-model="value" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <!-- 发布人 -->
          <div class="addworkorder-gridItem">
            <span class="addworkorder-gridItem-title">发布人:</span>
            <el-input v-model="input" placeholder="请输入内容"></el-input>
          </div>
          <!-- 发布人电话 -->
          <div class="addworkorder-gridItem">
            <span class="addworkorder-gridItem-title">发布人电话:</span>
            <el-input v-model="input" placeholder="请输入内容"></el-input>
          </div>
          <!-- 接收人片区 -->
          <div class="addworkorder-gridItem">
            <span class="addworkorder-gridItem-title">接收人片区:</span>
            <el-select v-model="value" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <!-- 接收人 -->
          <div class="addworkorder-gridItem">
            <span class="addworkorder-gridItem-title">接收人:</span>
            <el-input v-model="input" placeholder="请输入内容"></el-input>
          </div>
          <!-- 接收人电话 -->
          <div class="addworkorder-gridItem">
            <span class="addworkorder-gridItem-title">接收人:</span>
            <el-input v-model="input" placeholder="请输入内容"></el-input>
          </div>
          <!-- 公司名称 -->
          <div class="addworkorder-gridItem">
            <span class="addworkorder-gridItem-title">公司名称:</span>
            <el-input v-model="input" placeholder="请输入内容"></el-input>
          </div>
          <!-- 联系人 -->
          <div class="addworkorder-gridItem">
            <span class="addworkorder-gridItem-title">联系人:</span>
            <el-input v-model="input" placeholder="请输入内容"></el-input>
          </div>
          <!-- 联系电话 -->
          <div class="addworkorder-gridItem">
            <span class="addworkorder-gridItem-title">联系人电话:</span>
            <el-input v-model="input" placeholder="请输入内容"></el-input>
          </div>
          <!-- 公司地址 -->
          <div class="addworkorder-gridItem ComponeyAddress">
            <span class="addworkorder-gridItem-title">公司地址:</span>
            <el-input
              v-model="input"
              placeholder="请输入内容"
              class="Companyaddress"
              type="textarea"
              :rows="2"
            ></el-input>
          </div>
          <!-- 办理片区 -->
          <div class="addworkorder-gridItem handleArea">
            <span class="addworkorder-gridItem-title">办理片区:</span>
            <el-select v-model="value" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <!-- 服务项目 -->
        <div class="addworkorder-serviceContainer">
          <p class="myorderdetails-service-title">服务项目:</p>
          <el-checkbox-group v-model="serviceList">
            <el-checkbox label="注册公司"></el-checkbox>
            <el-checkbox label="变更公司"></el-checkbox>
            <el-checkbox label="注销公司"></el-checkbox>
            <el-checkbox label="资质办理"></el-checkbox>
            <el-checkbox label="代开对公账号"></el-checkbox>
            <el-checkbox label="开通社保"></el-checkbox>
            <el-checkbox label="税务异常处理"></el-checkbox>
            <el-checkbox label="工商异常处理"></el-checkbox>
            <el-checkbox label="申请发票"></el-checkbox>
            <el-checkbox label="代开专票"></el-checkbox>
            <el-checkbox label="刻章"></el-checkbox>
            <el-checkbox label="签合同(收款)"></el-checkbox>
            <el-checkbox label="其他"
              >其他<el-input
                placeholder="其他服务项目(具体写)"
                class="other-input"
              ></el-input
            ></el-checkbox>
          </el-checkbox-group>
        </div>
        <!-- 客户提供资料 -->
        <div class="addworkorder-custermerInfoContainer">
          <p class="addworkorder-custermerInfo-title">客户提供资料:</p>
          <el-checkbox-group v-model="serviceList">
            <el-checkbox label="营业执照正本"></el-checkbox>
            <el-checkbox label="营业执照副本"></el-checkbox>
            <el-checkbox label="公章"></el-checkbox>
            <el-checkbox label="开户许可证"></el-checkbox>
            <el-checkbox label="租凭合同"></el-checkbox>
            <el-checkbox label="房产证复印件"></el-checkbox>
            <el-checkbox label="发票章"></el-checkbox>
            <el-checkbox label="税盘"></el-checkbox>
            <el-checkbox label="身份证"></el-checkbox>
            <el-checkbox label="其他"
              >其他<el-input
                placeholder="其他服务项目(具体写)"
                class="other-input"
              ></el-input
            ></el-checkbox>
          </el-checkbox-group>
        </div>
        <!-- 发布日期 、到期日期-->
        <div class="addworkorder-dateContainer">
          <span class="addworkorder-date-title">发布日期:</span>
          <el-date-picker
            type="datetime"
            placeholder="请选择日期"
            suffix-icon="el-icon-date"
            v-model="time"
            class="releasetimeInput"
          >
          </el-date-picker>
          <span class="addworkorder-date-title">到期日期:</span>
          <el-date-picker
            type="datetime"
            placeholder="请选择日期"
            suffix-icon="el-icon-date"
            v-model="time"
            class="expirationtimeInput"
          >
          </el-date-picker>
        </div>
        <!-- 抄送人 -->
        <div class="addworkorder-csrContainer">
          <div class="addworkorder-csrLabel-Container">
            <span class="addworkorder-csr-title">是否抄送:</span>
            <el-radio-group v-model="radio">
              <el-radio label="1">是</el-radio>
              <el-radio label="2">否</el-radio>
            </el-radio-group>
          </div>
          <div class="addworkorder-csrSearch-Container">
            <div class="addworkorder-csrSearchItem-Container">
              <span class="addworkorder-csr-title">抄送人:</span>
              <el-input
                placeholder="请输入内容"
                prefix-icon="el-icon-search"
                v-model="input"
              >
              </el-input>
            </div>
            <div class="addworkorder-csrSearchRadio-box">
              <!-- 此处应该要用输入框来动态生成 -->
              <el-radio v-model="radio" label="1" border>天灿</el-radio>
              <el-radio v-model="radio" label="2" border>天灿灿</el-radio>
            </div>
          </div>
        </div>
        <!-- 任务备注 -->
        <div class="addworkorder-TasknoteContainer">
          <span class="addworkorder-Tasknote-title">任务备注:</span>
          <template>
            <quill-editor v-model="input" ref="content"> </quill-editor>
          </template>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addworkorderVisibleClose">取消</el-button>
        <el-button class="addBtn">发布</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import 'quill/dist/quill.core.css'
// import 'quill/dist/quill.snow.css'
// import 'quill/dist/quill.bubble.css'

// import { quillEditor } from 'vue-quill-editor'
export default {
  // components: {
  //   quillEditor
  // },
  data() {
    return {
      options: [{
        value: "1",
        label: "南宁总部"
      },
      {
        value: "2",
        label: "南宁高新"
      },
      {
        value: "3",
        label: "南宁青秀"
      },
      {
        value: "4",
        label: "南宁江南"
      },
      {
        value: "5",
        label: "玉林"
      },
      {
        value: "6",
        label: "柳州"
      },
      {
        value: "7",
        label: "桂林"
      },
      {
        value: "8",
        label: "百色"
      },
      {
        value: "9",
        label: "来宾"
      },
      {
        value: "10",
        label: "梧州"
      },
      {
        value: "11",
        label: "钦州"
      }],
      value: '',
      input: '',
      serviceList: [],
      time: '',
      radio: '1'
    }
  },
  props: {
    addworkorderVisible: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    addworkorderVisibleClose() {
      this.$emit('addworkorderVisibleClose')
    }
  }
}
</script>

<style lang="scss">
.addworkorder-dialog {
  .el-dialog__header {
    background-color: $index-buttonPor-color;
    font-size: 20px;
    font-weight: bold;
    .el-dialog__title {
      color: #ffffff;
      font-size: 20px;
      font-weight: bold;
    }
    .el-dialog__headerbtn {
      font-size: 20px;
      color: #ffffff;
      .el-icon-close {
        color: #ffffff;
      }
    }
  }
  .el-dialog__body {
    height: 940px;
    overflow: auto;
    padding: 20px;
    .addworkorder-container {
      min-height: 300px;
      // border-bottom: 2px solid #f2f2f2;
      padding: 20px 0;
      box-sizing: border-box;
      .addworkorder-gridContainer {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-row-gap: 20px;
        grid-column-gap: 20px;
        border-bottom: 2px solid #f2f2f2;
        padding-bottom: 30px;
        .addworkorderflex-top {
          align-items: flex-start !important;
        }
        .addworkorder-biggridItem {
          grid-column-start: span 2;
        }
        .addworkorder-gridItem {
          display: flex;
          align-items: center;
          .addworkorder-gridItem-title {
            width: 100px;
          }
          .el-input,
          .el-autocomplete {
            flex: 1;
          }
          .el-select .el-input__inner {
            text-align: center;
            height: 30px;
            width: 180px;
            background-color: #f9f9f9;
            border-radius: 0;
          }
          .el-select .el-input__icon {
            position: relative;
            line-height: 30px;
            // top: 6px;
          }
          .el-input .el-input__inner {
            height: 30px;
            width: 180px;
            border-radius: 0;
          }
          .Companyaddress .el-input__inner {
            height: 30px;
          }
        }
        .ComponeyAddress,
        .handleArea {
          align-items: flex-start;
          .el-textarea {
            width: 180px;
            .el-textarea__inner {
              border-radius: 0px;
              color: #606266;
            }
          }
        }
      }
      /*服务项目*/
      .addworkorder-serviceContainer {
        padding: 20px 0;
        border-bottom: 2px solid #f2f2f2;
        .el-checkbox-group {
          padding: 20px;

          .el-checkbox {
            margin: 10px 0px 20px 10px;
            .other-input .el-input__inner {
              height: 30px;
              width: 200px;
              border-radius: 0;
              margin-left: 10px;
            }
          }
          .el-checkbox .el-checkbox__input.is-checked + .el-checkbox__label {
            color: #606266;
          }
          .el-checkbox__label {
            width: 100px;
          }
          .el-checkbox .el-checkbox__input.is-checked .el-checkbox__inner,
          .el-checkbox
            .el-checkbox__input.is-indeterminate
            .el-checkbox__inner {
            border-color: #8674eb;
            background-color: #8674eb;
          }
        }
      }
      /*客户提供资料 */
      .addworkorder-custermerInfoContainer {
        padding: 20px 0;
        border-bottom: 2px solid #f2f2f2;
        .el-checkbox-group {
          padding: 20px;

          .el-checkbox {
            margin: 10px 0px 20px 10px;
            .other-input .el-input__inner {
              height: 30px;
              width: 200px;
              border-radius: 0;
              margin-left: 10px;
            }
          }
          .el-checkbox .el-checkbox__input.is-checked + .el-checkbox__label {
            color: #606266;
          }
          .el-checkbox__label {
            width: 100px;
          }
          .el-checkbox .el-checkbox__input.is-checked .el-checkbox__inner,
          .el-checkbox
            .el-checkbox__input.is-indeterminate
            .el-checkbox__inner {
            border-color: #8674eb;
            background-color: #8674eb;
          }
        }
      }
      // 发布日期、到期日期
      .addworkorder-dateContainer {
        display: flex;
        padding: 20px 0;
        .addworkorder-date-title {
          width: 90px;
          height: 30px;
          line-height: 30px;
        }
        .el-input .el-input__inner {
          height: 30px;
          line-height: 30px;
          background-color: #f9f9f9;
          border-radius: 0;
        }
        .el-input .el-input__icon {
          line-height: 30px;
        }
        .releasetimeInput {
          margin-right: 60px;
        }
      }
      /*抄送 */
      .addworkorder-csrContainer {
        display: flex;
        padding: 15px 0;
        .addworkorder-csrLabel-Container {
          display: flex;
          .addworkorder-csr-title {
            width: 90px;
            height: 30px;
            line-height: 30px;
          }
          .el-radio,
          .el-radio__input {
            line-height: 30px;
          }
          .el-radio__input.is-checked .el-radio__inner {
            border-color: #8674eb;
            background-color: #fff;
          }
          .el-radio__inner::after {
            width: 9px;
            height: 9px;
            background-color: #8674eb;
          }
          .el-radio__input.is-checked + .el-radio__label {
            color: #606266;
          }
        }
        .addworkorder-csrSearch-Container {
          margin-left: 30px;
          // display: flex;
          .addworkorder-csrSearchItem-Container {
            display: flex;
            .addworkorder-csr-title {
              width: 90px;
              height: 30px;
              line-height: 30px;
              margin-left: 145px;
            }
            .el-input {
              width: 200px;
            }
            .el-input .el-input__inner {
              height: 30px;
              border-radius: 0;
            }
            .el-input .el-input__icon {
              line-height: 30px;
              position: relative;
              left: 170px;
            }
            .el-input--prefix .el-input__inner {
              padding-right: 30px;
              padding-left: 10px;
            }
          }
          .addworkorder-csrSearchRadio-box {
            padding-left: 235px;
            margin-top: 15px;
            .el-radio.is-bordered {
              border-radius: 0;
              padding: 0;
              height: 30px;
              line-height: 30px;
              padding-right: 12px;
              margin-right: 0px;
              width: 75px;
            }
            .el-radio.is-bordered.is-checked {
              border-color: #8674eb;
            }
            .el-radio__input.is-checked .el-radio__inner {
              border-color: #8674eb;
              background: #fff;
            }
            .el-radio.is-bordered .el-radio__inner {
              width: 12px;
              height: 12px;
              // position: absolute;
              // left: 59px;
              // top: -15px;
            }
            .el-radio__input.is-checked + .el-radio__label {
              color: #606266;
            }

            .el-radio__input.is-checked .el-radio__inner::after {
              content: "";
              width: 7px;
              height: 3px;
              border: 1px solid #8674eb;
              border-top: transparent;
              border-right: transparent;
              text-align: center;
              display: block;
              position: absolute;
              top: 2px;
              left: 2px;
              vertical-align: middle;
              transform: rotate(-45deg);
              border-radius: 0px;
              background: none;
            }
          }
        }
      }
      // 任务备注
      .addworkorder-TasknoteContainer {
        display: flex;
        padding: 20px 0px;
        .addworkorder-Tasknote-title {
          width: 90px;
          height: 30px;
          line-height: 30px;
        }
      }
    }
  }
  .el-dialog__footer {
    .dialog-footer {
      .el-button {
        border: 1px solid $index-buttonPor-color;
        color: $index-buttonPor-color;
      }
      .addBtn {
        background-color: $index-buttonPor-color;
        color: #ffffff;
      }
    }
  }
}
</style>